<template>
    <breadcrumb></breadcrumb>
    <user-form :data="userInfo" type="admin" title="用户资料"></user-form>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import UserForm from '@/components/forms/UserForm.vue';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';

@Options({
    components: {
        UserForm, Breadcrumb
    },
    data(){
        return{
            userInfo: {}
        }
    },
    mounted(){
        this.$api.getUserInfo().then((res: any) => {
            console.log(res)
            this.userInfo = res.data;
        })
    }
})

export default class UserProfile extends Vue {}
</script>

<style lang="scss" scoped>
.user-profile{
    @extend .flex-column;
    margin: 10px;
    padding: 15px 20px;
    border-radius: $radius;
    background-color: $white;
    box-shadow: $shadow;
}
</style>