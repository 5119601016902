
import { Options, Vue } from 'vue-class-component';
import UserForm from '@/components/forms/UserForm.vue';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';

@Options({
    components: {
        UserForm, Breadcrumb
    },
    data(){
        return{
            userInfo: {}
        }
    },
    mounted(){
        this.$api.getUserInfo().then((res: any) => {
            console.log(res)
            this.userInfo = res.data;
        })
    }
})

export default class UserProfile extends Vue {}
